<template>
    <div v-show="!accepted" :class="{ 'hidden': !isClient }" class="fixed maw-w-xs mx-6 sm:max-w-xs p-4 sm:mx-auto bg-white border border-gray-200 dark:bg-gray-800 sm:left-8 z-40 sm:bottom-8 bottom-2 dark:border-gray-700 rounded-2xl">
        <h2 class="font-semibold text-gray-800 dark:text-white">🍪 {{ $t('cookies-pop-up.title') }}</h2>

        <p class="mt-4 text-sm text-gray-600 dark:text-gray-300">{{ $t('cookies-pop-up.text') }}</p>

        <div class="flex items-center justify-around mt-4 gap-x-4 shrink-0">
            <NuxtLink :to="localePath('/cookies-policy')" class="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                {{ $t('cookies-pop-up.read-more') }}
            </NuxtLink>

            <button @click="acceptCookies" class="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                {{ $t('cookies-pop-up.accept') }}
            </button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';

const { t } = useI18n()
const accepted = ref(false);
const isClient = ref(false);

const acceptCookies = () => {
    accepted.value = true;
    localStorage.setItem('cookiesAccepted', 'true');
};

const checkAcceptedCookies = () => {
    accepted.value = localStorage.getItem('cookiesAccepted') === 'true';
};

onMounted(async () => {
    await nextTick();
    isClient.value = true;
    checkAcceptedCookies();
});
</script>
