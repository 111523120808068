<template>
    <header class="absolute inset-x-0 top-0 z-40">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1" v-show="!mobileMenuOpen">
                <NuxtLink :to="localePath('/')" class="-m-1.5 p-1.5 focus-visible:outline-none">
                    <span class="sr-only">{{ $t('company.name') }}</span>
                    <UtilsLogoMVE />
                </NuxtLink>
            </div>
            <div class="flex lg:hidden" v-show="!mobileMenuOpen">
                <LangSwitcher class="mr-2.5" />
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
                    <span class="sr-only">{{ $t('header.open-main-menu') }}</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <PopoverGroup class="hidden lg:flex lg:gap-x-12">
                <NuxtLink :to="localePath('/')" class="text-sm font-semibold leading-6 text-gray-900 text-blanc">{{ $t('header.nav.home') }}</NuxtLink>

                <Popover class="relative" v-slot="{ open, close }">
                    <PopoverButton class="focus-visible:outline-none flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" @mouseover="(e) => hoverPopover(e, open)" @mouseleave="closePopover(close)">
                        {{ $t('header.nav.services') }}
                        <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                        <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 popover-content" @mouseover.prevent="popoverHover = true" @mouseleave.prevent="closePopover(close)">
                            <div class="p-4">
                                <div v-for="item in products" :key="item.name" class="group relative flex gap-x-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50">
                                    <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-red-600" aria-hidden="true" />
                                    </div>
                                    <div class="flex-auto">
                                        <NuxtLink :to="localePath(item.href)" class="block font-semibold text-gray-900" @click="closePopover(close)">
                                            {{ item.name }}
                                            <span class="absolute inset-0" />
                                        </NuxtLink>
                                        <p class="mt-1 text-gray-600 text-sm">{{ item.description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                <NuxtLink v-for="item in callsToAction" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                                    <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                    {{ item.name }}
                                </NuxtLink>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>

                <Popover class="relative" v-slot="{ open, close }">
                  <PopoverButton class="focus-visible:outline-none flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" @mouseover="(e) => hoverPopover(e, open)" @mouseleave="closePopover(close)">
                    {{ $t('header.nav.resources') }}
                    <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </PopoverButton>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                    <PopoverPanel @mouseover.prevent="popoverHover = true" @mouseleave.prevent="closePopover(close)" class="absolute -left-8 top-full z-10 mt-3 w-96 rounded-3xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5 popover-content">
                      <div v-for="(item, index) in resources" :key="item.name" class="relative rounded-lg p-4 hover:bg-gray-50">
                        <NuxtLink :to="localePath(item.href)" class="block text-sm font-semibold leading-6 text-gray-900" @click="closePopover(close)">
                          {{ item.name }}
                          <span class="absolute inset-0"></span>
                        </NuxtLink>
                        <p class="mt-1 text-sm leading-6 text-gray-600">{{ item.description }}</p>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>

                <Popover class="relative" v-slot="{ open, close }">
                  <PopoverButton class="focus-visible:outline-none flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" @mouseover="(e) => hoverPopover(e, open)" @mouseleave="closePopover(close)">
                    {{ $t('header.nav.contact') }}
                    <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </PopoverButton>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                    <PopoverPanel @mouseover.prevent="popoverHover = true" @mouseleave.prevent="closePopover(close)" class="absolute -left-8 top-full z-10 mt-3 w-96 rounded-3xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5 popover-content">
                      <div v-for="(item, index) in company" :key="item.name" class="relative rounded-lg p-4 hover:bg-gray-50">
                        <NuxtLink :to="localePath(item.href)" class="block text-sm font-semibold leading-6 text-gray-900" @click="closePopover(close)">
                          {{ item.name }}

                          <!-- Vérifie si c'est le dernier élément -->
                          <span v-if="index === 0" class="ml-2 bg-blue-200 text-blue-800 text-xs px-2 py-1 rounded-full">
                                    {{ $t('header.new') }}
                                </span>

                          <span class="absolute inset-0"></span>
                        </NuxtLink>
                        <p class="mt-1 text-sm leading-6 text-gray-600">{{ item.description }}</p>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>

                <NuxtLink :to="localePath('/about-us')" class="text-sm font-semibold leading-6 text-gray-900">{{ $t('header.nav.about') }}</NuxtLink>
            </PopoverGroup>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <LangSwitcher />
            </div>
        </nav>

        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel class="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="p-6">
                    <div class="flex items-center justify-between">
                        <NuxtLink :to="localePath('/')" class="-m-1.5 p-1.5 focus-visible:outline-none" @click="mobileMenuOpen = false">
                            <span class="sr-only">{{ $t('company.name') }}</span>
                            <UtilsLogoMVE />
                        </NuxtLink>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                            <span class="sr-only">{{ $t('header.close-main-menu') }}</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <NuxtLink @click="mobileMenuOpen = false" v-for="item in products" :key="item.name" :to="localePath(item.href)" class="group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-red-600" aria-hidden="true" />
                                    </div>
                                    {{ item.name }}
                                </NuxtLink>
                            </div>
                            <div class="space-y-2 py-6">
                                <NuxtLink @click="mobileMenuOpen = false" :to="localePath('/')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ $t('header.nav.home') }}</NuxtLink>
                                <NuxtLink @click="mobileMenuOpen = false" :to="localePath('/about-us')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ $t('header.nav.about') }}</NuxtLink>
                                <NuxtLink @click="mobileMenuOpen = false" v-for="item in company" :key="item.name" :to="localePath(item.href)" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sticky bottom-0 grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 text-center">
                    <NuxtLink v-for="item in callsToAction" :key="item.name" :href="item.href" class="p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100">{{ item.name }}</NuxtLink>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Bars3Icon,
  ShieldCheckIcon,
  WifiIcon,
  HomeModernIcon,
  CloudIcon,
  XMarkIcon,
  CubeIcon,
  CommandLineIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/vue/20/solid'
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'

const { t, locale } = useI18n()

const products = computed(() => [
  { name: t('services.cyber-security'), description: t('services.cyber-security.description'), href: '/cybersecurity', icon: ShieldCheckIcon },
  { name: t('services.network'), description: t('services.network.description'), href: '/network', icon: WifiIcon },
  { name: t('services.cloud'), description: t('services.cloud.description'), href: '/cloud', icon: CloudIcon },
  { name: t('services.blockchain'), description: t('services.blockchain.description'), href: '/blockchain', icon: CubeIcon },
  { name: t('services.devops'), description: t('services.devops.description'), href: '/devops', icon: CommandLineIcon },
])

const resources = computed(() => [
  { name: t('header.resources.blog'), href: '/blog', description: t('header.resources.blog.description')},
/*  { name: t('header.resources.events'), href: '/events', description: t('header.resources.events.description') }*/
])

const callsToAction = computed(() => [
  { name: t('header.calls-to-action.send-mail'), href: 'mailto:info@mve.mc', icon: EnvelopeIcon },
  { name: t('header.calls-to-action.call-sales'), href: 'tel:+37797975103', icon: PhoneIcon },
])

const company = computed(() => [
  { name: t('header.company.fonds-bleu'), href: '/fond-bleu', description: t('header.company.fonds-bleu.description'), },
  { name: t('header.company.careers'), href: '/careers', description: t('header.company.careers.description') },
  { name: t('header.company.support'), href: '/support', description: t('header.company.support.description'), },
  { name: t('header.company.certifications'), href: '/certifications', description: t('header.company.certifications.description') },
])

const mobileMenuOpen = ref(false)

const popoverHover = ref(false)
const popoverTimeout = ref(null)

const hoverPopover = (e, open) => {
  popoverHover.value = true
  if (!open) e.target.click()
}

const closePopover = (close) => {
  popoverHover.value = false
  if (popoverTimeout.value) clearTimeout(popoverHover.value)
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) close()
  }, 100)
}

defineExpose({ closePopover });

watch(locale, () => {
  products.value = products.value.slice();
  callsToAction.value = callsToAction.value.slice();
  company.value = company.value.slice();
})
</script>

