<script setup lang="ts">
const { status } = useAuth()
const statusValue = status.value
</script>

<template>
    <div>
        <UtilsToast />
        <Header />
        <CookieConsent />
        <ButtonContactUs />

      <main class="isolate">
        <slot />
      </main>
      <div class="fixed bottom-10 left-10 z-20">
        <UButton
            v-if="statusValue === 'authenticated'"
            icon="i-heroicons-adjustments-horizontal"
            size="xl"
            class="bg-red-800 rounded-full cursor-pointer"
            variant="solid"
            :trailing="false"
            :to="localePath('/admin/dashboard')"
        />
      </div>
    </div>
</template>

<style>
    img, button, input:focus-visible {
        outline: none;
    }
     .page-enter-active,
     .page-leave-active {
         transition: all 0.5s;
     }
    .page-enter-from,
    .page-leave-to {
        opacity: 0;
        filter: blur(1rem);
    }
</style>
